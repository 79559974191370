<template>
  <div class="col-sm-6 col-md-4 col-xl-3">
    <div class="my-4 text-center">
      <b-modal
        id="approvalModal"
        ref="approvalModal"
        size="xl"
        :title="'Affecter la liste des intervenants '"
        title-class="font-18"
        hide-footer
      >
        <form @submit.prevent="submit">
          <div class="row">
            <div class="col-md-12">
              <label for="formrow-inputCity">Liste des employés *</label>
              <multiselect 
                :select-label="''"
                :deselect-label="''" 
                :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" v-model="assignEmployeesToMoForm.employeesList" :searchable="true" track-by="id" label="fullName" :options="employees" placeholder="Selectionner les employés" :allow-empty="false">
                <template slot="singleEmployee" slot-scope="{ employee }">{{ employee.fullName }}</template>
              </multiselect>
            </div>
          </div> <br>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="formrow-inputCity">Commentaires *</label>
                <textarea
                  v-model="assignEmployeesToMoForm.comments"
                  class="form-control"
                  cols="30"
                  rows="4"
                ></textarea>
              </div>
            </div>
          </div>

          <div>
            <button type="submit" class="btn btn-label btn-primary float-right ml-2" >
              <i class="far fa-check-square text-white label-icon"></i> Valide 
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: {
      moId: {
          type: Number,
      },

      supplierId: null,
      maintenanceOrderData : undefined,

      moExecutionType: {
          type: String,
      },
  },
  components: {Multiselect},
  data() {
    return {
      show: false,
      employees: [],
      assignEmployeesToMoForm: {
        moId: "",
        requestUid: "",
        comments: "",
        employeesList: [],
      },

      executionResponsibleTypes: [
        {
          id: 'company',
          title: 'SONATRACH',
        },

        {
          id: 'supplier',
          title: 'Prestataire'
        }
      ],
      supId : null,
      assignedEmployeesList : [],
    };
  },
  mounted: function () { 
      // this.fetchEmployeesList();
    // this.$root.$on('showAssignEmployeesModal', () => { // here you need to use the arrow function
     
    // })
  },
  watch : {
    supplierId : function (val){
        if(val){
          this.supId = val;
          this.realoadSingleMaintenanceOrderData = false;
        }
    },
    moExecutionType : function(val){
      if(val){
        console.log("val");
      }
    },
    maintenanceOrderData: function (val){
        if(val){
          this.supId                             = val;
          this.realoadSingleMaintenanceOrderData = false;
          this.assignedEmployeesList             = val.assignedEmployees;
          this.assignEmployeesToMoForm.comments  = val.employee_assignment_comments;
          this.fetchEmployeesList();
        }
    }
  },
  methods: {
    submit() {
        this.assignEmployeesToMoForm.moId = this.$props.moId;
        this.$http
        .post("/ops/maintenance_orders/assignEmployeesToMo", this.assignEmployeesToMoForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success("l'operation a ete effectuée avec succes");
              this.$refs['approvalModal'].hide()
              this.$emit('realoadSingleMaintenanceOrderData',true)
              break;

            case 500:
              this.$toast.error(res.data.original.msg);
              
              break;
          }
        })
        .catch(() => {
          
        })
        .finally(() => {});
    },
   fetchEmployeesList(typeEmp){
      var type = typeEmp;
      var supplier = {"id" : this.supId}; 
      this.$http
       .post("/system/users/employeesListAll",{type: type,supplier :supplier})
        // .post("/ops/requests/maintenance/usersList",{type: type,supplier :supplier})
        .then((res) => {
          this.assignEmployeesToMoForm.employeesList = "";  
          this.employees = [];
          this.employees = res.data.original.data 
          this.assignEmployeesToMoForm.employeesList = this.assignedEmployeesList
          })
        .catch(() => {})
        .finally(() => {});
    },
    /* fetchEmployeesList(){
         fetchEmployeesListApi({supplierId: this.$props.supplierId})
        .then((res) => (this.employees = res.data.original.list))
        .catch(() => {})
        .finally(() => {});   
    } */
  },
};
</script>
